import { useCallback } from 'react';
import { Column } from '@tanstack/react-table';
import { CSSProperties } from 'react';

const SELECT_COLUMN_ID = 'select';

type BorderType = ('bottom' | 'right')[];

export const useTableViewStyles = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCommonPinningStyles = useCallback((column: Column<any>, border?: BorderType): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
    const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');
    const isResizing = column.getIsResizing();
    const isSelectColumn = column.id === SELECT_COLUMN_ID;

    let boxShadow = isLastLeftPinnedColumn ? '-4px 0 4px -4px gray inset' : isFirstRightPinnedColumn ? '4px 0 4px -4px gray inset' : undefined;

    if (isResizing) {
      boxShadow = boxShadow
        ? `${boxShadow}, inset -2px 0px 0px 0px rgba(var(--color-accent-1), 1)`
        : 'inset -2px 0px 0px 0px rgba(var(--color-accent-1), 1)';
    }

    if (isSelectColumn || border?.includes('bottom')) {
      boxShadow = boxShadow ? `${boxShadow}, inset 0 -2px rgba(var(--color-background-1), 1)` : 'inset 0 -2px rgba(var(--color-background-1), 1)';
    }

    if (!isSelectColumn && border?.includes('right')) {
      boxShadow = boxShadow ? `${boxShadow}, inset -1px 0 rgba(var(--color-gray-5), 1)` : 'inset -1px 0 rgba(var(--color-gray-5), 1)';
    }

    return {
      boxShadow,
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
      position: isPinned ? 'sticky' : 'relative',
      width: column.getSize(),
      maxWidth: column.getSize(),
      zIndex: isPinned ? 1 : undefined,
    };
  }, []);

  return { getCommonPinningStyles };
};
